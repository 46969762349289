import React from "react";

const CV = () => {
  const url =
    "/cv.pdf";
  return (
    <>
      <div className="flex flex-col h-full mt-2 mr-10">
        <embed
          type="application/pdf"
          className="w-full h-full mb-2 ml-4"
          src={url}
        />
      </div>
    </>
  );
};

export default CV;
