import { Skill } from "../models/Skill";



const Skills = (): Skill[] =>{
    return [
        {name: 'dotnet'},
        {name: 'mssql'},
        {name: 'azure'},
        {name: 'aws'},
        {name: 'terraform'},
        {name: 'github'},
        {name: 'react'},
        {name: 'nextjs'},
    ]
}


export default Skills;